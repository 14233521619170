// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-components-header-header-jsx": () => import("./../../../src/pages/components/header/header.jsx" /* webpackChunkName: "component---src-pages-components-header-header-jsx" */),
  "component---src-pages-components-header-hr-jsx": () => import("./../../../src/pages/components/header/hr.jsx" /* webpackChunkName: "component---src-pages-components-header-hr-jsx" */),
  "component---src-pages-components-header-quote-jsx": () => import("./../../../src/pages/components/header/quote.jsx" /* webpackChunkName: "component---src-pages-components-header-quote-jsx" */),
  "component---src-pages-components-main-content-main-content-jsx": () => import("./../../../src/pages/components/MainContent/MainContent.jsx" /* webpackChunkName: "component---src-pages-components-main-content-main-content-jsx" */),
  "component---src-pages-components-main-content-shit-svg-jsx": () => import("./../../../src/pages/components/MainContent/ShitSvg.jsx" /* webpackChunkName: "component---src-pages-components-main-content-shit-svg-jsx" */),
  "component---src-pages-components-main-content-us-button-jsx": () => import("./../../../src/pages/components/MainContent/UsButton.jsx" /* webpackChunkName: "component---src-pages-components-main-content-us-button-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

